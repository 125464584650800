export const searchStatus = [
    {
        text: 'Unpaid',
        value: 'UNPAID'
    },
    {
        text: 'Awaiting writer',
        value: 'PAID'
    },
    {
        text: 'In progress',
        value: 'PENDING'
    },
    {
        text: 'Complete',
        value: 'COMPLETE'
    },
    {
        text: 'In Revision',
        value: 'REVISION'
    },
    {
        text: 'Refund',
        value: 'REFUND'
    }
]
