<template lang="html">
    <div>
        <div class="wrapper dashboard_header-wrapper">
            <AccountTopBar />
        </div>
        <InviteWidget />
        <div class="profile-wrapper wrapper page-content">
            <section class="profile-home">
                <!-- projects -->
                <router-link
                    :to="{ name: 'orders' }"
                    class="profile-home__link-container"
                >
                    <div class="profile-home__card profile-home__card_order">
                        <div class="profile-home__card-title">
                            Projects
                        </div>
                        <div
                            v-if="loading"
                            class="profile-home__card-statuses"
                        >
                            <template
                                v-for="(item, index) in counters.orders"
                            >
                                <div
                                    v-if="item.counters.count"
                                    :key="index"
                                    class="profile-home__card-status"
                                >
                                    <span class="profile-home__card-status__name">
                                        <router-link
                                            :to="{
                                                name: 'orders',
                                                query: { preset: item.value }
                                            }"
                                        >
                                            <!-- {{ $t(`components.order_filter.statuses.${status}.statusReadable`) }} -->
                                            {{ item.title }}
                                        </router-link>
                                    </span>
                                    <span
                                        class="profile-home__card-count"
                                        :class="`counter-${item.value}`"
                                    >
                                        {{ item.counters.count }}
                                    </span>
                                </div>
                            </template>
                        </div>
                        <template v-else>
                            <div class="preset-filter_loader">
                                <PresetLoader />
                            </div>
                        </template>
                        <svg
                            height="50"
                            width="41"
                        >
                            <use
                                class="test"
                                :xlink:href="`${require('@/assets/img/account/sprites.svg')}#0`"
                            />
                        </svg>
                    </div>
                </router-link>
                <!-- projects -->

                <!-- support -->
                <router-link
                    :to="{ name: 'tickets', params: { page: '1' } }"
                    class="profile-home__link-container"
                >
                    <div class="profile-home__card profile-home__card_support">
                        <div class="profile-home__card-title">
                            Help Center
                        </div>
                        <div
                            v-if="loading"
                            class="profile-home__card-statuses"
                        >
                            <template v-for="(item, index) in counters.support">
                                <div
                                    v-if="item.counters.count"
                                    :key="index"
                                    class="profile-home__card-status"
                                >
                                    <span class="profile-home__card-status__name">
                                        <router-link
                                            :to="{
                                                name: 'tickets',
                                                query: { preset: item.value }
                                            }"
                                        >
                                            <!-- {{ $t(`components.order_filter.statuses.${status}.statusReadable`) }} -->
                                            {{ item.title }}
                                        </router-link>
                                    </span>
                                    <span
                                        class="profile-home__card-count"
                                        :class="`counter-${item.title}`"
                                    >
                                        {{ item.counters.count }}
                                    </span>
                                </div>
                            </template>
                        </div>
                        <template v-else>
                            <div class="preset-filter_loader">
                                <PresetLoader />
                            </div>
                        </template>
                        <svg
                            height="50"
                            width="63"
                        >
                            <use :xlink:href="`${require('@/assets/img/account/sprites.svg')}#1`" />
                        </svg>
                    </div>
                </router-link>
                <!-- support -->

                <!-- billing -->
                <router-link
                    :to="{ name: 'billing', params: {} }"
                    class="profile-home__link-container"
                >
                    <div class="profile-home__card profile-home__card_billing">
                        <span class="profile-home__card-title">
                            Billing
                        </span>
                        <svg
                            height="50"
                            width="44"
                        >
                            <use :xlink:href="`${require('@/assets/img/account/sprites.svg')}#2`" />
                        </svg>
                    </div>
                </router-link>
                <!-- billing -->

                <!-- credits -->
                <router-link
                    :to="{ name: 'credits', params: {} }"
                    class="profile-home__link-container"
                >
                    <div class="profile-home__card profile-home__card_credit">
                        <span class="profile-home__card-title">
                            Credits
                        </span>
                        <span class="account-balance">
                            {{ getterMainClientInfo.account_balance | money }}
                        </span>
                        <svg
                            height="50"
                            width="52"
                        >
                            <use :xlink:href="`${require('@/assets/img/account/sprites.svg')}#3`" />
                        </svg>
                    </div>
                </router-link>
                <!-- credits -->

                <!-- account settings -->
                <router-link
                    :to="{ name: 'profile', params: {} }"
                    class="profile-home__link-container"
                >
                    <div class="profile-home__card profile-home__card_settings">
                        <span class="profile-home__card-title">
                            Settings
                        </span>
                        <svg
                            height="50"
                            width="51"
                        >
                            <use :xlink:href="`${require('@/assets/img/account/sprites.svg')}#4`" />
                        </svg>
                    </div>
                </router-link>
                <!-- account settings -->

                <!-- friends -->
                <router-link
                    :to="{ name: 'friends', params: {} }"
                    class="profile-home__link-container"
                >
                    <div class="profile-home__card profile-home__card_friends">
                        <div class="profile-home__card-title">
                            Referrals
                        </div>
                        <svg
                            height="50"
                            width="44"
                        >
                            <use :xlink:href="`${require('@/assets/img/account/sprites.svg')}#5`" />
                        </svg>
                    </div>
                </router-link>
                <!-- friends -->
            </section>
        </div>
    </div>
</template>

<script>
import Api from '@/helpers/api/index.js';
import { mapGetters, createNamespacedHelpers } from 'vuex'
import filtersMixin from '@/mixins/filtersMixin.js'
import { searchStatus } from '@/services/orderStatusHelper/filterStatuses.js'
import {
    ACTION_UPDATE_CLIENT_DATA
} from '@/store/modules/client/action-types'

// Components
import InviteWidget from '@/components/friends/InviteWidget.vue';
import PresetLoader from '@/components/common/PresetLoader';
import AccountTopBar from '@/components/AccountTopBar';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

export default {
    name: 'ClientAccountPage',
    components: {
        AccountTopBar,
        PresetLoader,
        InviteWidget
    },
    mixins: [
        filtersMixin
    ],
    data() {
        return {
            searchStatus,
            counters: null,
            loading: true,
            showTopUp: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo'
        ])
    },
    async created() {
        await this.getCounters()
        await this[ACTION_UPDATE_CLIENT_DATA]()
    },
    methods: {
        ...mapClientActions([
            ACTION_UPDATE_CLIENT_DATA
        ]),
        async getCounters() {
            try {
                this.loading = false
                const { data } = await Api.get('/api/dashboard/get-counters')
                this.counters = data
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = true
            }
        }
    }
}
</script>
